import {type IEntryData} from "../../views/templates/live-demo-utils";

export class CreateDocumentFormBuilder {
  static createAndSubmitForm({template_ids, template_version_ids, ordered_template_ids, template_pack_id, entries}: {template_ids?: number[], template_version_ids?: number[], ordered_template_ids?: number[], template_pack_id?: number, entries?: IEntryData[]}){
    const form = this.createForm()
    if(template_ids && template_ids.length)CreateDocumentFormBuilder.createInputs(template_ids, "template_ids[]", form)
    if(template_version_ids && template_version_ids.length) CreateDocumentFormBuilder.createInputs(template_version_ids, "template_version_ids[]", form)
    if(ordered_template_ids && ordered_template_ids.length) CreateDocumentFormBuilder.createInputs(ordered_template_ids, "ordered_template_ids[]", form)
    if(template_pack_id) CreateDocumentFormBuilder.createInputs([template_pack_id], 'template_pack_id', form)
    if(entries) CreateDocumentFormBuilder.createInputs([JSON.stringify(entries)], 'entries', form)
    CreateDocumentFormBuilder.appendCSRF(form)
    CreateDocumentFormBuilder.appendAndSubmitForm(form)
  }

  static appendCSRF(form){
    const input = document.createElement("input")
    input.setAttribute("name", 'authenticity_token');
    input.setAttribute("value", document.querySelector("meta[name='csrf-token']")!.getAttribute("content")!);
    input.style.display = "none"

    form.appendChild(input);
  }

  static appendAndSubmitForm(form){
    document.body.appendChild(form)
    form.submit()
  }

  static createInputs(values, name, form){
    values.forEach(value => {
      const input = document.createElement("input")
      input.setAttribute('type',"hidden")
      input.setAttribute('name', name)
      input.setAttribute('value', value)
      form.appendChild(input)
    })
  }

  static createForm(){
    const form = document.createElement('form')
    form.setAttribute('action', '/documents/new')
    form.setAttribute('method', 'post')
    form.classList.add('hidden')
    return form
  }
}
